import React, { useRef, useState, Suspense } from "react"
import { Canvas, useFrame } from "@react-three/fiber" //useLoader
// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js"
const Box = (props) => {
  // This reference will give us direct access to the mesh so we can animate it
  const mesh = useRef()

  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)

  // Rotate mesh every frame, this is outside of React without overhead
  useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.01))

  return (
    <mesh
      {...props}
      ref={mesh}
      scale={active ? [1.5, 1.5, 1.5] : [1, 1, 1]}
      onClick={(e) => setActive(!active)}
      onPointerOver={(e) => setHover(true)}
      onPointerOut={(e) => setHover(false)}
    >
      <boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
      <meshStandardMaterial
        attach="material"
        color={hovered ? "hotpink" : "orange"}
      />
    </mesh>
  )
}

// const Steve = () => {
//   const mesh = useRef()
//   const gltf = useLoader(GLTFLoader, "steve.glb")
//   useFrame(() => (mesh.current.rotation.y += 0.0001))
// //   const material1 = new THREE.MeshBasicMaterial({
// //     color: 0x00ff00,
// //     wireframe: true,
// //   })
// //   const mesh1 = new THREE.Mesh(mesh.geometry, material1)

//   return (
//     <Suspense fallback={null}>
//       <primitive
//         ref={mesh}
//         object={gltf.scene}
//         scale={0.5}
//         position={[-6, -6, -6]}
//       />
//       {/* <primitive mesh={mesh1} /> */}
//     </Suspense>
//   )
// }
const Avatar = () => {
  return (
    <Suspense fallback={null}>
      <Canvas>
        <ambientLight />
        <pointLight position={[10, 10, 10]} />

        <Box position={[-1.2, 0, 0]} />
        <Box position={[1.2, 0, 0]} /> 

        {/* <Steve /> */}
      </Canvas>
    </Suspense>
  )
}

export default Avatar
