import React from "react"
import { graphql } from 'gatsby'
import "twin.macro"
import Marquee from "react-fast-marquee"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Avatar from "../components/avatar"
import Teaser from "../components/teaser"

const isBrowser = typeof window !== "undefined"
const Index = ({data, location}) => {
  const stories = data.stories.edges.map(({node}) => node)
  return (
    <Layout>
      <Seo title="kpfwlt web3.0" />
      <div tw="bg-orange">
        <div tw="min-h-screen font-regular relative z-10">
          <div tw="mb-20 text-red-dark">
            <Marquee gradient={false}>
              <div tw="font-extended text-8xl tracking-tighter -mt-4 leading-none overflow-hidden">
                {" "}
                kpfwlt kpfwlt kpfwlt kpfwlt kpfwlt kpfwlt kpfwlt kpfwlt kpfwlt
                kpfwlt kpfwlt kpfwlt{" "}
              </div>
            </Marquee>
          </div>

          <div tw="flex justify-center">
            <div tw="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-5xl mx-4">
              {stories.map((story) => (
                <Teaser blok={story} location={location} key={story.id} />
              ))}
            </div>
          </div>
        </div>
        <div tw="absolute inset-0 flex items-center justify-center z-0">
        {isBrowser && (
          <Avatar />
        )}
        </div>
      </div>
    </Layout>
  )
}

export default Index

export const query = graphql`
  {
    stories: allStoryblokEntry(filter: {field_component: {eq: "story"}}) {
      edges {
        node {
          id
          field_component
          content
        }
      }
    }
  }
`