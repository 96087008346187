import React from "react"
// import PropTypes from "prop-types"
import "twin.macro"
import useStoryblok from "../hooks/storyblok"

const Teaser = ({ blok, location }) => {
  const story = useStoryblok(blok, location)
  return (
    <div tw="col-span-1" key="{story.id}">
      <div tw="rounded bg-black" />
      <h3 tw="font-extended text-2xl mb-3 text-white leading-none">
        {story.content.title}
      </h3>
      {/* <div tw="text-grey-100 text-sm mb-3">
        22.01.2022
      </div> */}
      <p tw="text-grey-900 text-lg leading-tight">{story.content.excerpt}</p>
    </div>
  )
}

Teaser.propTypes = {}

export default Teaser
