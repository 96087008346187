module.exports = {
  siteMetadata: {
    title: ` by Kopfwelt`,
    description: ``,
    author: `@kopfwelt`,
  },
  plugins: [
    `gatsby-plugin-emotion`,
    `gatsby-plugin-postcss`,
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-source-storyblok`,
      options: {
        accessToken: `N7v7EztWKxmrkLgVCIAPwQtt`,
        homeSlug: `home`,
        version: process.env.NODE_ENV === `production` ? `published` : `draft`,
      },
    },
  ],
}
